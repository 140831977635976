import React from "react";
import {
  BackgroundImage,
  HeaderText,
  HeaderText2,
  ImageContainer,
  ImageText,
  MainAboutContainter,
  MainAboutText,
} from "./About.style";
import image from "../common/images/pexels-abdel-rahman-abu-baker-2537395.jpg";
import YouthComponent from "./youth";
import { useRecoilState } from "recoil";
import { handleClickState } from "../common/globalStateManager";
function About() {
  const [state, setState] = useRecoilState(handleClickState);


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = () => {
    setState(!state);
  }
  console.log(state)

  return (
    <div style={{ height: "100vh" }} id="/about">
      <ImageContainer>
        <BackgroundImage src={image} />
        <ImageText>
          {" "}
          <h1>We Honor One Another to Glorify God</h1>
        </ImageText>
      </ImageContainer>

      <MainAboutContainter>
        <MainAboutText>
          <HeaderText>Welcome To </HeaderText>
          <HeaderText2>International Baptist Ministries</HeaderText2>

          <p>
            At International Baptist Ministries, our roots trace back to the early days of the Baptist movement, which emerged in the 17th century as a response to the desire for a more personal and direct relationship with God. This tradition emphasizes the importance of individual faith, the authority of Scripture, and the believer's baptism as an outward expression of inner transformation.
            As we have grown, our church has embraced the diversity of the global community, welcoming people from various cultures and backgrounds. This international aspect reflects the Great Commission given by Jesus, calling us to go into all the world and make disciples of all nations. Our mission is to share the love of Christ, fostering a sense of belonging and purpose for everyone who walks through our doors.
            Worship is at the heart of our faith. We gather not just to sing songs or recite prayers, but to connect deeply with our Creator. Worship allows us to express our gratitude, seek guidance, and find peace amid life's challenges. It’s a communal experience where we uplift one another, celebrate our blessings, and recognize the presence of God in our lives.
            When we worship, we are reminded of God's unconditional love, grace, and mercy. Each act of worship—whether through music, prayer, or service—draws us closer to Him and to one another. It's a time to reflect on the beauty of creation, the hope found in Christ, and the promise of eternal life.
            At International Baptist Ministries, we invite you to join us in this transformative experience. Together, we can discover the joy and purpose that come from worshiping God and living out our faith in everyday life. We are a community united in love, ready to welcome you as you embark on your own spiritual journey.
          </p>

        </MainAboutText>
      </MainAboutContainter>
      <YouthComponent />
    </div>
  );
}

export default About;
