import image1 from "../common/images/IBMPHOTOS/kids.jpg";
import image2 from "../common/images/IBMPHOTOS/teen.jpg";
import image3 from  "../common/images/IBMPHOTOS/Youth.jpg";
import image4 from "../common/images/IBMPHOTOS/Parent.jpg" ;

export const youthData = [
  {
    image: image1,
    text:"kids"
  },
  {
    image: image2,
    text:'teen'
  },
  {
    image: image3,
    text:'youth'
  },
  {
    image: image4,
    text:'parent'
  },

];
