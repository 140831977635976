import image1 from '../common/images/pexels-luis-quintero-2014775.jpg'
import image2 from '../common/images/pexels-nappy-935944.jpg'
import image3 from '../common/images/30698056_487149951681758_3803542334902108160_n.jpg'

export const ImageData = [
  {
    image: image1,
    message:'A lot of people say a lot of things about Jesus, but the only wau to really get the thruth is to lsiten to this own words.',
    header:"What Did Jesus Say ",
    to:"/"
  },
  {
    image: image2,
    message:'A lot of people say a lot of things about Jesus, but the only wau to really get the thruth is to lsiten to this own words.',
    header:" What Did Jesus Say",
    to:"/"
  },
  {
    image: image3,
    message:'A lot of people say a lot of things about Jesus, but the only wau to really get the thruth is to lsiten to this own words.',
    header:" What Did Jesus Say",
    to:"/"
  },

];
