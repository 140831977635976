import React, { useState } from 'react';
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';

const EventPage = () => {
  const today = new Date();
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const [selectedDate, setSelectedDate] = useState(today);

  // Helper function to create a date in the future
  const futureDate = (days: number) => {
    const date = new Date(today);
    date.setDate(date.getDate() + days);
    return date;
  };

  const churchEvents = [
    { date: futureDate(2), time: '10:00am', title: 'Sunday Worship Service', description: 'Weekly worship service for all congregants' },
    { date: futureDate(5), time: '7:00pm', title: 'Bible Study', description: 'Midweek Bible study and prayer meeting' },
    { date: futureDate(7), time: '6:30pm', title: 'Youth Group', description: 'Fellowship and activities for teenagers' },
    { date: futureDate(9), time: '10:00am', title: 'Sunday Worship Service', description: 'Weekly worship service for all congregants' },
    { date: futureDate(12), time: '7:00pm', title: 'Bible Study', description: 'Midweek Bible study and prayer meeting' },
    { date: futureDate(14), time: '9:00am', title: 'Men\'s Breakfast', description: 'Monthly men\'s fellowship breakfast' },
    { date: futureDate(16), time: '10:00am', title: 'Sunday Worship Service', description: 'Weekly worship service for all congregants' },
    { date: futureDate(19), time: '7:00pm', title: 'Bible Study', description: 'Midweek Bible study and prayer meeting' },
    { date: futureDate(21), time: '6:30pm', title: 'Women\'s Ministry', description: 'Monthly women\'s fellowship and Bible study' },
    { date: futureDate(23), time: '10:00am', title: 'Sunday Worship Service', description: 'Weekly worship service for all congregants' },
    { date: futureDate(26), time: '7:00pm', title: 'Bible Study', description: 'Midweek Bible study and prayer meeting' },
    { date: futureDate(28), time: '6:30pm', title: 'Community Outreach', description: 'Local community service project' },
    { date: futureDate(30), time: '10:00am', title: 'Sunday Worship Service', description: 'Weekly worship service for all congregants' },
    { date: futureDate(33), time: '7:00pm', title: 'Bible Study', description: 'Midweek Bible study and prayer meeting' },
    { date: futureDate(35), time: '6:30pm', title: 'Family Movie Night', description: 'Fun evening for families with kid-friendly movie' },
  ];

  const filteredEvents = churchEvents.filter(event => 
    event.date.toDateString() === selectedDate.toDateString()
  );

  const handleDateSelect = (date: React.SetStateAction<Date>) => {
    setSelectedDate(date);
  };

  return (
    <div className="flex h-screen bg-gray-100" style={{ marginTop: "250px", background: "#152238" }} id='/events'>
      {/* Main content */}
      <div className="flex-1 p-8">
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-white">Church Events</h1>
        </header>

        <div className="flex">
          {/* Calendar */}
          <div className="w-1/3 bg-white rounded-lg p-4 mr-8">
            <InfiniteCalendar
              width={400}
              height={600}
              selected={selectedDate}
              onSelect={handleDateSelect}
              minDate={lastWeek}
              theme={{
                selectionColor: '#3182ce',
                textColor: {
                  default: '#333',
                  active: '#FFF'
                },
                weekdayColor: '#3182ce',
                headerColor: '#3182ce',
                floatingNav: {
                  background: '#3182ce',
                  color: '#FFF',
                  chevron: '#FFF'
                }
              }}
            />
          </div>

          {/* Event list */}
          <div className="w-2/3">
            <h2 className="text-lg font-semibold mb-4 text-white">Events for {selectedDate.toDateString()}</h2>
            {filteredEvents.length > 0 ? (
              filteredEvents.map((event, index) => (
                <div key={index} className="bg-white rounded-lg p-4 mb-4 flex">
                  <div className="w-24 text-center">
                    <div className="text-blue-600">{event.time}</div>
                  </div>
                  <div>
                    <h3 className="font-semibold">{event.title}</h3>
                    <p className="text-gray-600">{event.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-white">No events scheduled for this date.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPage;